<template>
  <main id="thank-smartmeter" class="next-container">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100">
          Vielen Dank für Ihre Zählerstandsmeldung
        </h1>

        <p class="text-subtitle color-accent-300">
          Regelmäßige Zählerstandsmeldungen helfen Ihnen und uns dabei, einen
          guten Überblick über den Verlauf Ihres Strompakets zu behalten. Wir
          bedanken uns für Ihre Mithilfe. Sie können diese Seite nun schließen
          oder weiter zu Ihrem Dashboard gehen, wo Sie den Verlauf Ihres
          Strompakets einsehen können. Für den Zugriff auf das Dashboard ist
          eine Anmeldung notwendig. Bitte nutzen Sie dazu Ihre „mein eprimo”
          Zugangsdaten.
        </p>

        <div class="margin-button">
          <button-regular
            v-if="$route.params.uuid"
            @click="passToMeterReading"
            class="button-accent"
          >
            weiter zum Dashboard
          </button-regular>
        </div>
      </div>
    </section-base>
  </main>
</template>

<script>
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { ButtonRegular, SectionBase },

  methods: {
    passToMeterReading() {
      this.$store.commit('user/SET_CURRENT_FLOW', 'mr_dashboard');
      this.$store.dispatch('user/GET_METER_READING_DATA', {
        uuid: this.$route.params.uuid,
        push: true
      });
    }
  }
};
</script>

<style></style>
